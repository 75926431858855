
import Swal from 'sweetalert2'
import moment from 'moment-timezone'
import VideochatComments from './VideochatComments.vue'
import AccessPermissionsModal from './AccessPermissionsModal.vue'
import Chat from './Chat.vue'
import ActionsBar from './ActionsBar.vue'
import AccessPermissions from './AccessPermissions.vue'
import store from '@/store'
import Pusher from 'pusher-js';
import { mapGetters } from 'vuex'
import { GRANT_TESTER_ACCESS, FETCH_TESTER_JOIN_SLOT, FETCH_FOCUS_DATA_OBS, FETCH_FOCUS_DATA_MODERATOR, FETCH_WAT_FOCUS_DATA, FETCH_ONLINE_USERS, FETCH_WAT_FOCUS_USERS, WAT_FOCUS_PUT_MESSAGE_OBSERVATOR, WAT_FOCUS_PUT_MESSAGE, FETCH_WAT_FOCUS_CHAT_MESSAGES_OBS, FETCH_WAT_FOCUS_CHAT_MESSAGES, ADD_FOCUS_CHAT_MESSAGE, ADD_FOCUS_OBSERVER_CHAT_MESSAGE } from '../../store/watfocus.store'
import { initSession, connectSession, getSession, getPublisher, createSignal, muteCurrentUser, canShareScreen, applyBlurEffect } from '../../utils/videochat-restyling.js'
export default {
  name: 'WatFocus',
  components:{
    Chat,
    ActionsBar,
    AccessPermissions,
    VideochatComments,
    AccessPermissionsModal
  },
  props:{
    badgePermissions:{
        type: Number,
        default: 0
    },
    activityMembers:Object,
    isClient:{
      type: Boolean,
      default: false
    },
    isUser:{
      type: Boolean,
      default: false
    },
    isObservator:{
      type: Boolean,
      default: false
    },
    isModerator:{
      type: Boolean,
      default: false
    },
    isPublic:{
      type: Boolean,
      default: false
    },
    nickname:{
      type: String,
      default:""
    }
  },
  computed:{
    ...mapGetters([
      'focusChatMessagesObs',
      'focusChatMessages'
    ])
  },
  data(){
    return{
      selectedTab:0,
      isFullSize:false,
      isMuted:false,
      isMutedByModerator:false,
      isSharingScreen: false,
      isSharingScreenExternal: false,
      screenPublisher: null,
      badgeChatActivity:0,
      recordingStartedDateInMs: 0,
      currentTimeFromStart: 0,
      currentSecondsFromStart: 0,
      showPermissionsModal: false,
      deniedUsers:[],
      permissionUserName:'',
      testerName:'',
      isBlurEffect: false,
      watFocusTesters:[],
      accessUsers:[],
      chatPusher:null,
      chatConnected: false,
      badgeChatObserver:0,
      watFocusData:{
        testerNum:0,
        slot:{
          dateFrom:''
        }
      }

    }
  },
  emits: ['hide-header'],
  watch: {
    badgeChatObserver: async function(val){
      if(this.selectedTab == 1 && this.badgeChatObserver > 0){
        this.badgeChatObserver = 0
      }
    },
    badgePermissions: {
        async handler(val) {
            if(this.showPermissionsModal) return
            if(this.isObservator) return
            for(let index in this.accessUsers){
                const user = this.accessUsers[index]
                if(!user.accessGranted && user.online && !this.deniedUsers.includes(index)) {
                    this.permissionUserName = index
                    this.testerName = user.testerName
                    this.showPermissionsModal = true
                    return
                }
            }
      },
      immediate: true
    }
  },
  methods:{
    exit(){
      this.destroySession()
      this.$emit('exit')
    },
    destroySession(){
      if(getSession() != null){
        getSession().off();
        getSession().disconnect();
      }
      if(getPublisher() != null) getPublisher().destroy()
      if(this.screenPublisher != null && this.isSharingScreen) this.screenPublisher.destroy()
    },
    changeTab(tabIndex){
      if(this.selectedTab == tabIndex) return this.selectedTab = -1
      if(tabIndex == 1) this.$emit('observer-chat-opened')
      if(tabIndex == 0) this.badgeChatActivity = 0
      if(tabIndex == 3) this.openCommentsModal()
      this.selectedTab = tabIndex
    },
    fullSize(){
      this.isFullSize = !this.isFullSize
      store.commit('setHideHeader',this.isFullSize)
    },
    detectArchivingStart(data){
      /*let vm = this
      getSession().on("archiveStarted", function(event) {
        store.dispatch(COMMUNITY_VIDEOCHAT_FILE,{archiveId:event.id})
        .then(({data}) => {
          vm.recordingStartedDateInMs = data.createdAt
        })
      });*/
    },
    detectStreamDestroyed(data){
      let vm = this
      getSession().on("streamDestroyed", function(event) {
        if (vm.screenPublisher != null && event.stream === vm.screenPublisher.stream) {
          vm.isSharingScreenExternal = false
        }
      });
    },
    detectNewStream(data){
      let vm = this
      getSession().on('streamCreated', function(event) {
        const isScreenStream = event.stream.videoType === 'screen'
        const DOMElement = isScreenStream ? 'Videochat--Screen' : 'Videochat--StreamsFakeContainer'
        const isModerator = event.stream.connection.data.split("=")[1] == "Moderador" ? true : false
        const insertMode = isScreenStream ? 'append' : isModerator ? 'before' : 'after'

        var subscriberProperties = {
          insertMode: insertMode,
          width: '100%',
          height: '100%',
          style: {buttonDisplayMode: 'off',nameDisplayMode: 'on', audioBlockedDisplayMode:'on'}
        };
        let subscriber = getSession().subscribe(event.stream, DOMElement, subscriberProperties, function (error) {
          if(event.stream.videoType == 'screen') vm.isSharingScreenExternal = true
          if(vm.isModerator) vm.generateUserActions(subscriber, event.stream.videoType == 'screen')
        });
      });
    },
    detectSignals(data){
      const vm = this
      getSession().on("signal", function(event) {
        const eventType = event.data.type
        if(eventType == 'MUTE') vm.executeSignalMute(event, true)
        else if(eventType == 'UNMUTE') vm.executeSignalMute(event, false)
        else if(eventType == 'REMOVE_USER') vm.executeSignalRemoveUser(event)
        else if(eventType == 'CLOSE_USER_SCREEN') vm.executeSignalCloseScreen(event)
      });
    },
    executeSignalMute(event, mute){
      if(mute) $('#'+event.data.userId).append("<div class='UserMutedIcon'><i class='fas fa-volume-mute'></i></div>")
      else $('#'+event.data.userId + ' .UserMutedIcon').remove()
      if(getPublisher().streamId == event.data.id && !this.isModerator){
        this.isMutedByModerator = mute
        muteCurrentUser(!mute)
      }
    },
    executeSignalRemoveUser(event){
      if(getPublisher().streamId == event.data.id && !this.isModerator){
        Swal.fire({
          text: event.data.message,
          icon:"error",
          customClass:{popup:"swal-customError"}
        })
        this.$router.push({name:'tests.index'})
        this.exit()
      }
    },
    executeSignalCloseScreen(event){
      if(!this.isModerator) this.stopScreenSharing()
    },
    generateUserActions(subscriber, isScreenStream){
      $( "#"+subscriber.id).append("<div class='UserMenuActions'><i class='fas fa-ellipsis-v'></i></div>")
      $( "#"+subscriber.id).append("<div class='UserMenuActions--List'><p class='UserMenuActions--Exit'><i class='fas fa-user-times'></i>"+(isScreenStream ? "Cerrar pantalla" : "Expulsar") +"</p><p class='UserMenuActions--Mute'><i class='fas fa-microphone-alt UserMenuActions--notMute'></i><i class='fas fa-microphone-alt-slash UserMenuActions--muted'></i>Silenciar</p></div>")
      this.generateClickEventHandlers(subscriber, isScreenStream)
    },
    generateClickEventHandlers(subscriber, isScreenStream){
      const vm = this

      $(window).click(function() {
        $( "#"+subscriber.id+" .UserMenuActions--List").hide()
      });

      $( "#"+subscriber.id+" .UserMenuActions" ).on( "click", function(event) {
        event.stopPropagation();
        const element = $( "#"+subscriber.id+" .UserMenuActions--List")
        if ( element.is(":visible") ) element.hide()
        else element.show().css('display', 'flex')
      });

      $( "#"+subscriber.id+" .UserMenuActions--Mute" ).on( "click", function(event) {
        event.stopPropagation();
        const element = $( "#"+subscriber.id+" .UserMenuActions--Mute")
        if (element.hasClass("userMute")){
          createSignal(subscriber.id, subscriber.streamId,"external","UNMUTE",null)
          element.removeClass('userMute')
        }
        else {
          createSignal(subscriber.id, subscriber.streamId,"external","MUTE",null)
          element.addClass('userMute')
        }
      });

      $( "#"+subscriber.id+" .UserMenuActions--Exit" ).on( "click", function(event) {
        event.stopPropagation();
        if(isScreenStream){
          Swal.fire({
            text: vm.$t('user_will_stop_sharing_screen'),
            icon: 'warning',
            cancelButtonText: vm.$t('action_cancel'),
            confirmButtonText: vm.$t('action_continue'),
            showCancelButton: true,
            customClass:{
              popup:'swal-customWarning'
            }
          }).then((result) => {
            if (result.isConfirmed) {
              createSignal(subscriber.id, subscriber.streamId,"external","CLOSE_USER_SCREEN")
            }
          })
        }
        else{
          Swal.fire({
          title: vm.$t('msg_videochat_out_message'),
          text: vm.$t('msg_videochat_out_reason'),
          input: 'text',
          showCancelButton: true,
          cancelButtonText: vm.$t('action_cancel'),
          confirmButtonText: vm.$t('action_out')
          }).then((result) => {
            if (result.isConfirmed && result.value != null) {
              vm.$emit('user-removed',subscriber.stream.name)
              vm.deniedUsers.push(subscriber.stream.name)
              createSignal(subscriber.id, subscriber.streamId,"external","REMOVE_USER",result.value)
            }
          })
        }
      });

    },
    hasPermissionsToShare(){
      return !this.isObservator && (this.isUser && !this.isSharingScreenExternal) || this.isModerator
    },
    showScreenPermissionError(){
      let vm = this
      Swal.fire({
        text: vm.$t('check_your_permissions'),
        icon:"error",
        customClass:{popup:"swal-customError"}
      })
    },
    publishScreenShare(){
      let vm = this

      let participantName = this.nickname

      const publisherOptions = {
        name: this.isClient ? "Moderador" : participantName,
        insertMode: 'append',
        width: '100%',
        height: '100%',
        videoSource : 'screen',
        nameDisplayMode: "on"
      }

      this.screenPublisher = OT.initPublisher('Videochat--Screen', publisherOptions, function(error) {
        if(error){
          vm.isSharingScreen = false
          if(error.originalMessage != 'Permission denied') vm.showScreenPermissionError()
        }
        else {
          getSession().publish(vm.screenPublisher, function(error) {
            if (error) {
              vm.isSharingScreen = false
              vm.showScreenPermissionError()
              if (error.name != null && error.name == 'OT_UNEXPECTED_SERVER_RESPONSE') {
                console.log("SERVER ERROR: OpenTok unexpected error")
              }
            }
            vm.isSharingScreen = true
          });
        }
      });

      this.screenPublisher.on({
        streamDestroyed: function (event) {
            vm.isSharingScreen = false
        }
      });
    },
    shareScreen(){
      if(!canShareScreen()){
        let vm = this
        Swal.fire(vm.$t('browser_can_not_share_screen'))
        return
      }

      if(this.isSharingScreen){
        this.stopScreenSharing()
        return
      }

      this.publishScreenShare()
    },
    stopScreenSharing(){
      this.isSharingScreen = false
      this.screenPublisher.destroy()
    },
    setStreamsGridSize(){
      var usersNum = this.watFocusData.testerNum
      if(this.isObservator) usersNum++
      if(usersNum == 2 ) $('.Videochat--Streams').addClass("Stream--min")
      if(usersNum >= 3 && usersNum <=4) $('.Videochat--Streams').addClass("Stream--few")
      if(usersNum >= 5 && usersNum<=6) $('.Videochat--Streams').addClass("Stream--medium")
      if(usersNum >= 7 && usersNum<=8) $('.Videochat--Streams').addClass("Stream--almostCompleted")
      if(usersNum >= 9 && usersNum<=12) $('.Videochat--Streams').addClass("Stream--full")
      if(usersNum >= 13) $('.Videochat--Streams').addClass("Stream--infinite")
    },
    mute(){
      if(this.isMutedByModerator) return
      this.isMuted = !this.isMuted
      muteCurrentUser(!this.isMuted)
    },
    newMessageChat(message){
      if((this.isUser && message.isModerator) || (this.isClient && !message.isModerator)) this.badgeChatActivity++
    },
    closeTabsOnmobileView(){
      if(window.innerWidth <= 768) this.selectedTab = -1
    },
    async openCommentsModal(){
      const currentDate = new Date();
      const start = new Date(this.recordingStartedDateInMs);
      const hours = parseInt(Math.abs(start - currentDate) / (1000 * 60 * 60) % 24);
      const minutes = parseInt(Math.abs(start.getTime() - currentDate.getTime()) / (1000 * 60) % 60);
      const seconds = parseInt(Math.abs(start.getTime() - currentDate.getTime()) / (1000) % 60);
      this.currentTimeFromStart = ( hours < 10 ? '0'+hours : hours ) + ':' + ( minutes < 10 ? '0'+minutes : minutes ) + ':' + ( seconds < 10 ? '0'+seconds : seconds )
      this.currentSecondsFromStart = parseInt(Math.abs((currentDate - start) / 1000))
    },
    async sendVideoFileComment(comment){
      const videoCommentData = {
        secondsFromStart: this.currentSecondsFromStart,
        comment: comment
      }

      /*await store.dispatch(COMMUNITY_VIDEOCHAT_FILE_COMMENT,{data : videoCommentData})
      .then((data) => {
        let vm = this
        Swal.fire('',vm.$t('annotation_saved'),'success')
        this.changeTab(-1)
      })*/
    },
    grantAccessFromModal(event){
      let user = this.accessUsers[event.userName]
      user.accessGranted = event.accessGranted;
      if( event.accessGranted ) this.grantAccessToTester(user)
      else this.deniedUsers.push(user.nickName)
      this.showPermissionsModal = false
    },
    applyBlurEffect(){
      this.isBlurEffect = !this.isBlurEffect
      applyBlurEffect(this.isBlurEffect)
    },
    /* NEW WATFOCUS FUNCTIONS */
    async joinToWatFocus(sessionData){
        if(sessionData.sessionId != undefined && sessionData.sessionId != null){
          let participantName = this.nickname
          initSession(sessionData.sessionId)
          connectSession(sessionData.token, this.isObservator, this.isClient, participantName)
          this.detectNewStream(sessionData)
          this.detectStreamDestroyed(sessionData)
          this.detectSignals(sessionData)
          if(this.isModerator) this.detectArchivingStart(sessionData)
          this.setStreamsGridSize()
        }
    },
    async joinTester(){
      await store.dispatch(FETCH_TESTER_JOIN_SLOT,{id: this.$route.params.id,slotHash: this.$route.params.slotHash})
      .then((data) => {
        if(data.accessGranted){
          Swal.close()
          this.joinToWatFocus(data)
        }
        else {
          Swal.fire({
            text:this.$t('msg_videochat_waiting_permission'),
            icon:"info",
            customClass:{popup:"swal-customInfo"},
            allowOutsideClick: false,
            showCancelButton: false,
            showConfirmButton: false
          })
          setTimeout(this.joinTester, 5000)
        }
      })
    },
    async joinModerator(){
      await store.dispatch(FETCH_FOCUS_DATA_MODERATOR,{id: this.$route.params.id,slotHash: this.$route.params.slotHash})
      .then((data) => {
        this.joinToWatFocus(data)
      })
      .catch((response: any)  => {
        Swal.fire("","Sesión no disponible hasta " + this.watFocusData.slot.dateFrom,"error")
      })
      
    },
    async joinObservator(){
      await store.dispatch(FETCH_FOCUS_DATA_OBS,{id: this.$route.params.id,slotHash: this.$route.params.slotHash})
      .then((data) => {
        this.joinToWatFocus(data)
      })
      .catch((response) => {
        Swal.fire({
          text:this.$t('msg_videochat_waiting_permission'),
          icon:"info",
          customClass:{popup:"swal-customInfo"},
          allowOutsideClick: false,
          showCancelButton: false,
          showConfirmButton: false
        })
        setTimeout(this.joinObservator, 5000)
      })
    },
    async fetchWatFocus(){
      await store.dispatch(FETCH_WAT_FOCUS_DATA,{id: this.$route.params.id,slotHash: this.$route.params.slotHash})
      .then((data) => {
        this.watFocusData = data
      })
    },
    fetchOnlineUsers(){
      store.dispatch(FETCH_ONLINE_USERS,{id: this.$route.params.id,slotHash: this.$route.params.slotHash})
      .then((data) => {
        this.onlineUsers = data.onlineUsers
        this.mediaTestResults = data.testResults.testResults
        for(let id in this.onlineUsers.testersOnline){
          let videochatUser = _.filter(this.watFocusTesters, function (m) { return m.identifier == id })[0]
          if(videochatUser != undefined){
            if (this.mediaTestResults[videochatUser.nickname] != undefined){
              this.mediaTestResults[videochatUser.nickname].online = this.onlineUsers.testersOnline[id]
              this.mediaTestResults[videochatUser.nickname].identifier = id
            }
          }
        }
        this.accessUsers = this.mediaTestResults
        setTimeout(this.fetchOnlineUsers, 3000)
      })
    },
    async fetchWatFocusTesters(){
      await store.dispatch(FETCH_WAT_FOCUS_USERS,{id: this.$route.params.id,slotHash: this.$route.params.slotHash})
      .then((data) => {
        this.watFocusTesters = data
      })
    },
    async grantAccessToTester(tester){
      await store.dispatch(GRANT_TESTER_ACCESS, {id:this.$route.params.id, slotHash:this.$route.params.slotHash,testerId:tester.identifier, grantAccess:{grantAccess:tester.accessGranted}})
    },
    subscribeChat(){
      if(this.chatConnected) return
      this.chatConnected = true
      Pusher.logToConsole = false;
      this.chatPusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
        cluster: 'eu',
        forceTLS: true,
      });

      this.chatPusher.subscribe('chat-' + this.$route.params.id + '-slot-' + this.$route.params.slotHash)
      this.chatPusher.subscribe('chat-' + this.$route.params.id + '-slot-' + this.$route.params.slotHash + '-observer')
      this.chatPusher.bind('chat-message', this.handleReceivedMessageVideochat)
    },
    handleReceivedMessageVideochat(message){
      if(message.chatName == 'chat-' + this.$route.params.id + '-slot-' + this.$route.params.slotHash + '-observer'){
        message.creationDate = moment(message.creationDate).format('YYYY-MM-DDTHH:mm:ss');
        message.creationDate += '.307+0000'
        store.commit(ADD_FOCUS_OBSERVER_CHAT_MESSAGE, message)
        if(!message.isModerator && this.isModerator && this.selectedTab == 0){
          this.badgeChatObserver++
        }
      }
      else {
        this.newMessageChat(message)
        message.creationDate = moment(message.creationDate).format('YYYY-MM-DDTHH:mm:ss');
        message.creationDate += '.307+0000'
        store.commit(ADD_FOCUS_CHAT_MESSAGE, message)
      }
    },
    async sendMessage(event){
      event.isModerator = this.isModerator
      const data = {
        id: this.$route.params.id,
        slotHash: this.$route.params.slotHash,
        isModerator: this.isModerator,
        data:event
      }
      if(this.selectedTab == 0)await store.dispatch(WAT_FOCUS_PUT_MESSAGE,data)
      else await store.dispatch(WAT_FOCUS_PUT_MESSAGE_OBSERVATOR,data)
    },
    fetchChatMessages(){
      store.dispatch(FETCH_WAT_FOCUS_CHAT_MESSAGES,{id:this.$route.params.id, slotHash: this.$route.params.slotHash})
    },
    fetchChatMessagesObs(){
      store.dispatch(FETCH_WAT_FOCUS_CHAT_MESSAGES_OBS,{id:this.$route.params.id, slotHash: this.$route.params.slotHash})
    }
  },
  async mounted(){
    this.fullSize()
    if(this.isClient) this.fetchChatMessagesObs()
    this.fetchChatMessages()
    this.subscribeChat()
    if(this.isModerator) await this.fetchWatFocusTesters()
    if(this.isModerator) this.fetchOnlineUsers()
    await this.fetchWatFocus()
    this.closeTabsOnmobileView()
    if(this.isUser) this.joinTester()
    else if(this.isObservator) this.joinObservator()
    else if(this.isModerator) this.joinModerator()
  }
}
