import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WatFocus = _resolveComponent("WatFocus")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_WatFocus, {
            isUser: true,
            isPublic: _ctx.isPublic,
            nickname: _ctx.nickname
          }, null, 8 /* PROPS */, ["isPublic", "nickname"])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}